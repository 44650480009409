.all-questions {
    margin-top: 2em;
}
.all-questions .all-questions-header {
    text-align: center;
}
.all-questions .single-question {
    margin-left: 1em;
}

.all-questions .breadcrumbs {
    display: flex;
    width: 100%;
}
