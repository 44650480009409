.flash-card-carousel {
    padding: 7px;
}
.flash-card-carousel .next-button {
    float: right;
}
.flash-card-carousel .prev-button {
    float: left;
}
.flash-card-carousel .breadcrumbs {
    display: flex;
    width: 100%;
}
