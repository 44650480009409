html, body {
    overflow-x:hidden;
    width: 100vw;
}

.app {
    /*background-color: #d3d3d3;*/
    min-height: 120vh;
    position: relative;
    top: -20vh;
    padding-top: 20vh;
}

.app-content-container {
    min-height: 100vh;
}