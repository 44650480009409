.test-stepper {
    padding: 7px;
}
.test-stepper .next-button {
    float: right;
}
.test-stepper .submit-button {
    float: right;
    background-color: forestgreen;
}

.test-stepper .submit-button:hover {
    background-color: green;
}
.test-stepper .prev-button {
    float: left;
}
.test-stepper .breadcrumbs {
    display: flex;
    width: 100%;
}
